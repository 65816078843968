<template>
    <form @submit.prevent="update" @keydown="form.onKeydown($event)" class="mt-8">

        <card title="Password" :title-no-border="true" body-class="flex-wrap" class="border-radius overflow-hidden">

            <!-- Password -->
            <div class="form-group row w-1/3 pr-2">
                <modal-field :label="$t('new_password')">
                  <div class="col-md-7">
                      <input v-model="form.password" :class="{ 'is-invalid': form.errors.has('password') }" class="form-control" type="password" name="password">
                      <has-error :form="form" field="password" />
                  </div>
                </modal-field>
            </div>

            <!-- Password Confirmation -->
            <div class="form-group row w-1/3 pr-2">
                <modal-field :label="$t('confirm_password')">
                  <div class="col-md-7">
                      <input v-model="form.password_confirmation" :class="{ 'is-invalid': form.errors.has('password_confirmation') }" class="form-control" type="password" name="password_confirmation">
                      <has-error :form="form" field="password_confirmation" />
                  </div>
                </modal-field>
            </div>

            <!-- Submit Button -->
            <div class="form-group w-full mb-0">
                <button :loading="form.busy" class="ml-auto btn">
                    {{ $t('update') }}
                </button>
            </div>

        </card>
    </form>
</template>

<script>
import Form from 'vform'

export default {
  scrollToTop: false,

  metaInfo () {
    return { title: this.$t('settings') }
  },

  data: () => ({
    form: new Form({
      password: '',
      password_confirmation: ''
    })
  }),

  methods: {
    async update () {
      await this.form.patch(this.$apiUrl.settings.password)

        this.$snotify.success("Password changed");

      this.form.reset()
    }
  }
}
</script>
